(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-ui/assets/javascripts/chat-unread-count.js') >= 0) return;  svs.modules.push('/components/chat/chat-ui/assets/javascripts/chat-unread-count.js');

'use strict';

const _excluded = ["children"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
const MAX_COUNT = 99;
const ChatUnreadCount = _ref => {
  let {
      children: count
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return count ? React.createElement("span", props, count > MAX_COUNT ? "".concat(MAX_COUNT, "+") : count) : null;
};
ChatUnreadCount.defaultProps = {
  children: 0
};
setGlobal('svs.components.chat.chatUi.ChatUnreadCount', ChatUnreadCount);

 })(window);